<template>
  <div class="col-md-12">
    <v-row>
      <v-toolbar flat outlined>
        <v-icon x-large>mdi-package-variant-closed</v-icon>
        <v-toolbar-title>Mis paquetes</v-toolbar-title>
      </v-toolbar>
    </v-row>
    <v-row class="mt-10">
      <v-card class="mx-auto my-2" max-width="240" min-width="200" outlined>
        <v-list-item three-line>
          <v-list-item-content>
            <div class="text-overline mb-4">
              <v-icon x-large>mdi-airplane-clock</v-icon> En reserva
            </div>
            <!--<v-list-item-title class="text-h5 mb-1">
          Paquetes en reserva
        </v-list-item-title>
        <v-list-item-subtitle>Greyhound divisely hello coldly fonwderfully</v-list-item-subtitle>
        -->
          </v-list-item-content>
        </v-list-item>

        <v-card-actions>
          <v-btn block outlined rounded text large :to="'/mis_reservas_coord'">
            {{ totalReservado }}
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-card class="mx-auto my-2" max-width="240" min-width="200" outlined>
        <v-list-item three-line>
          <v-list-item-content>
            <div class="text-overline mb-4">
              <v-icon x-large>mdi-package-variant-closed-check</v-icon>
              Recibidos
            </div>
            <!-- <v-list-item-title class="text-h5 mb-1">
          Recibidos
        </v-list-item-title>
        <v-list-item-subtitle>Greyhound divisely hello coldly fonwderfully</v-list-item-subtitle> -->
          </v-list-item-content>
        </v-list-item>

        <v-card-actions>
          <v-btn outlined rounded text large block>
            {{ totalRecibido }}
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-card class="mx-auto my-2" max-width="240" min-width="200" outlined>
        <v-list-item three-line>
          <v-list-item-title>
            <div class="text-overline mb-4">
              <v-icon x-large>mdi-receipt-text</v-icon>
              Factura cargada
            </div>
            <!-- <v-list-item-title class="text-h5 mb-1">
          Factura pendiente
        </v-list-item-title>
        <v-list-item-subtitle>Greyhound divisely hello coldly fonwderfully</v-list-item-subtitle> -->
          </v-list-item-title>
        </v-list-item>

        <v-card-actions>
          <v-btn outlined rounded text large block>
            {{ totalFacturado }}
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-card class="mx-auto my-2" max-width="240" min-width="200" outlined>
        <v-list-item three-line>
          <v-list-item-content>
            <div class="text-overline mb-4">
              <v-icon x-large>mdi-airplane</v-icon> En transito
            </div>
            <!-- <v-list-item-title class="text-h5 mb-1">
          En transito
        </v-list-item-title>
        <v-list-item-subtitle>Greyhound divisely hello coldly fonwderfully</v-list-item-subtitle> -->
          </v-list-item-content>
        </v-list-item>

        <v-card-actions>
          <v-btn outlined rounded text large block>
            {{ totalEnTransito }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import router from "../../router";
import Vue from "vue";

export default {
  name: "IndicadoresFincaComponent",

  data: () => ({
    reservas: [],
    totalReservado: 0,
    totalRecibido: 0,
    totalFacturado: 0,
    totalEnTransito: 0,
  }),
  computed: {
    ...mapState("master", ["user", "loadingTable"]),
    ...mapState("access", ["loggedIn"]),
  },
  methods: {
    ...mapMutations("master", [
      "setUrl",
      "setLoadingBtn",
      "setLoadingTable",
      "setTitleToolbar",
    ]),

    ...mapActions("master", ["requestApi", "alertNotification"]),

    cargarReservas() {
      this.setLoadingTable(true);
      // console.log(this.destinoId.DEST_CIUDAD)

      this.setUrl("api/mis-reservas");
      this.requestApi({
        method: "GET",
        data: {},
      })
        .then((res) => {
          this.reservas = res.data.reservas;
          this.totalReservado = res.data.totalItems;
        })
        .then(() => {
          this.setLoadingTable(false);
        });
    },
  },
  mounted() {
    document.title = "Sistema de carga";
    this.setTitleToolbar("MIS RESERVAS");
    this.cargarReservas();
  },
};
</script>
