<template>
    <div class="col-md-12">
        <v-row>
            <v-toolbar flat outlined>
                <v-icon  x-large>mdi-clipboard-list-outline</v-icon>
                <v-toolbar-title>Mis tareas</v-toolbar-title>                
            </v-toolbar>
        </v-row>
    </div>
</template>

<script>
import {mapState, mapMutations, mapActions, mapGetters} from 'vuex'
 
import Vue from "vue";

export default {
  name: "IndicadoresFincaComponent",
   
  data: () => ({
    
    
  }),
  watch: {
    
  },
  computed: {
    ...mapState("master", [ "user","loadingTable"]),

    ...mapState("access", ["loggedIn"]),
  },
  methods: {
    ...mapMutations("master", ["setUrl", "setLoadingBtn","setLoadingTable","setTitleToolbar"]),

    ...mapActions("master", ["requestApi", "alertNotification"]),

    
     
       
         
    
  },
  mounted() {     
     document.title = "Sistema de carga"
     this.setTitleToolbar('MIS TAREAS')
      
     
  },
};
</script>