<template>
  <v-card flat tile>
    <v-overlay :value="overlay" absolute >
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-col cols="12">

       
          <IndicadoresFincaComponent v-if="user.tipo_usuario == 1" />
          <IndicadoresUsuarioAdmin v-if="user.tipo_usuario == 4" />
       
    </v-col>
  </v-card>
</template>

<script>

import { mapState, mapMutations, mapActions } from "vuex";
import Vue from "vue";
import IndicadoresFincaComponent from "./IndicadoresFincaComponent.vue";
import IndicadoresUsuarioAdmin from "./IndicadoresUsuarioAdmin.vue";

export default {
  name: "IndexComponent",
  components: {
    IndicadoresFincaComponent,
    IndicadoresUsuarioAdmin
},
  data: () => ({
    fav: false,
    message: false,
    hints: true,
    own: true,
    tab: null,
    
    overlay: false,
    totalItems: 1,
    currentPage: 1,
    itemsPerPage: 10,
  }),
  watch: {
    
  },
  computed: {
    ...mapState("master", [ "user"]),

    ...mapState("access", ["loggedIn"]),
  },
  methods: {
    ...mapMutations("master", ["setUrl", "setLoadingBtn"]),

    ...mapActions("master", ["requestApi", "alertNotification"]),

    

    

    
  },
  mounted() {
     
  },
};
</script>
